import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="About Julien Genestoux" />
      <h1>Julien Genestoux</h1>
      <p>You can also find me there (and probably many many other places that I have lost track of): </p>
      <ul>
        <li><a rel="me" href="https://twitter.com/julien51">Twitter</a></li>
        <li><a rel="me" href="https://www.linkedin.com/in/juliengenestoux/">LinkedIn</a></li>
        <li><a rel="me" href="https://github.com/julien51/">Github</a></li>
        <li><a rel="me" href="https://app.ens.domains/name/julien51.eth/details">ENS</a></li>
        <li><a rel="me" href="https://mastodon.cloud/@julien51">Mastodon</a></li>
      </ul>

      <p>
        I created <a href="https://jobetudiant.net/">Jobetudiant</a>, my first
        company, while still in school. After nearly 20 years, Jobetudiant is
        still one of the largest job board for students in France. I was
        nominated by BusinessWeek as one of Europe’s Best Entrepreneur Below 25
        in November 2006.
      </p>
      <p>
        In 2009, I founded <a href="https://superfeedr.com">Superfeedr</a> which
        became one of the leading real-time web API. Superfeedr received funding
        from Mark Cuban and Betaworks and became profitable less than a year
        later. Superfeedr was later acquired by{" "}
        <a href="https://medium.com">Medium</a>. At Medium, I lead the company’s
        SEO effort and quadrupled the share of traffic Medium receives from
        search. I also successfuly implemented syndication protocols such as
        Google’s Accelerated Mobile Pages and Facebook’s Intant Articles.
      </p>
      <p>
        I am a strong open web advocate. I co-authored the{" "}
        <a href="https://www.w3.org/TR/websub/">W3C WebSub protocol</a> and
        spoke at numerous international conferences and events such as Google
        IO, Le Web, Blend WebMix, JSConf EU. I also co-organized the Realtime
        Conf in the US and Europe.
      </p>
      <p>
        I invested in some of my friends’ startups and projects as well as
        advise a few companies who are trying to make the web a better place.
      </p>
      <p>
        I was born in France in 1982 and I currently live in Brooklyn, NY with
        my wife and three young kids, Hannah, Basile and Giselle.
      </p>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default About
